<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="3">
        <v-autocomplete
          v-model="userFilter"
          :items="users"
          clearable
          item-text="profile.full_name"
          item-value="id"
          :label="$t('filters.user')"
          required
          :no-data-text="$t('select.noDataAvailable')"></v-autocomplete>
      </v-col>

      <v-col cols="12" lg="2" md="2" sm="3">
        <v-menu
          ref="fromDateFilter"
          v-model="fromDateFilter"
          :close-on-content-click="false"
          :return-value.sync="fromDate"
          min-width="auto"
          offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              clearable
              :label="$t('filters.fromDate')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <DatePicker
            v-model="fromDate"
            :model-config="filterModelConfig"
            :max-date="new Date()"
            mode="date"
            :first-day-of-week="firstDay"
            @input="updateFromDateFilter(fromDate)">
          </DatePicker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="3">
        <v-menu
          ref="toDateFilter"
          v-model="toDateFilter"
          :close-on-content-click="false"
          :return-value.sync="toDate"
          min-width="auto"
          offset-y
          transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              clearable
              :label="$t('filters.toDate')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <DatePicker
            v-model="toDate"
            :model-config="filterModelConfig"
            :max-date="new Date()"
            mode="date"
            :first-day-of-week="firstDay"
            @input="updateToDateFilter(toDate)">
          </DatePicker>
        </v-menu>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="3" class="d-flex align-center justify-end">
        <work-logs-export :from-date="fromDate" :to-date="toDate" :userFilter="userFilter" :classes="'d-md-none'" />
      </v-col>
    </v-row>

    <v-row>
      <WorkLogsList
        :classes="'d-md-none'"
        :from-date="selectedFromDate"
        :to-date="selectedToDate"
        :user-filter="userFilter" />
    </v-row>
  </v-container>
</template>

<script>
import { DatePicker } from 'v-calendar/src/components';
import WorkLogsList from '@/components/work-logs/WorkLogsList.vue';
import store from '@/store';
import WorkLogsExport from '@/components/work-logs/WorkLogsExport.vue';

export default {
  name: 'VEMPG',
  components: { WorkLogsList, DatePicker, WorkLogsExport },
  data: () => ({
    userFilter: null,
    fromDateFilter: null,
    toDateFilter: null,
    fromDate: null,
    toDate: null,
    selectedToDate: null,
    selectedFromDate: null,
    users: [],
    filterModelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
  }),
  created() {
    this.loadAllUsers();
  },
  computed: {},
  methods: {
    async loadAllUsers() {
      await this.$store.dispatch('users/getAllUsers').then((res) => {
        this.users = res.data.map((e) => {
          e.active = parseInt(e.active);
          return e;
        });
      });
    },

    updateFromDateFilter(fromDate) {
      this.$refs.fromDateFilter.save(fromDate);
      this.selectedFromDate = fromDate;
    },
    updateToDateFilter(toDate) {
      this.$refs.toDateFilter.save(toDate);
      this.selectedToDate = toDate;
    },
  },

  watch: {},
};
</script>
