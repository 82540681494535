<template>
  <v-dialog v-model="exportExcelDialog" max-width="700px" class="no-transition" :key="exportExcelDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :class="classes" color="primary" dark v-bind="attrs" v-on="on">
        {{ $t('buttons.export') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="info title white--text font-weight-bold">
        {{ $t('buttons.export') }}
        <v-spacer />
        <v-icon class="mdi mdi-close" style="color: white" @click="closeExportDialog"></v-icon>
      </v-card-title>

      <validation-observer ref="exportExcelForm" v-slot="{ handleSubmit, failed }">
        <v-form lazy-validation @submit.prevent="handleSubmit(exportToExcel)">
          <v-container>
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="6">
                <v-menu
                  ref="fromDateFilterNew"
                  v-model="fromDateFilterNew"
                  :close-on-content-click="false"
                  :return-value.sync="fromDateNew"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider rules="required" v-slot="{ errors }" name="time_from">
                      <v-text-field
                        v-model="fromDateNew"
                        clearable
                        :label="$t('filters.fromDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </template>
                  <DatePicker
                    v-model="fromDateNew"
                    :model-config="filterModelConfig"
                    mode="date"
                    :max-date="new Date()"
                    :first-day-of-week="firstDay"
                    @input="updateFromDateFilter(fromDateNew)"></DatePicker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="6">
                <v-menu
                  ref="toDateFilterNew"
                  v-model="toDateFilterNew"
                  :close-on-content-click="false"
                  :return-value.sync="toDateNew"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider rules="required" v-slot="{ errors }" name="time_to">
                      <v-text-field
                        v-model="toDateNew"
                        clearable
                        :label="$t('filters.toDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors"></v-text-field>
                    </validation-provider>
                  </template>
                  <DatePicker
                    v-model="toDateNew"
                    :model-config="filterModelConfig"
                    mode="date"
                    :max-date="new Date()"
                    :first-day-of-week="firstDay"
                    @input="updateToDateFilter(toDateNew)"></DatePicker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />

            <button-submit :failed="failed" :loading="loadingExport" buttonText="buttons.export"></button-submit>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from '@/i18n/i18n';
import { DatePicker } from 'v-calendar/src/components';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDateToDDMM } from '@/utils/formatDate';
import store from '@/store';

export default {
  name: 'workLogsList',
  components: { DatePicker, ButtonSubmit },
  props: ['fromDate', 'toDate', 'userFilter', 'classes'],
  data: () => ({
    exportExcelDialog: false,
    fromDateFilterNew: null,
    toDateFilterNew: null,
    filterModelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY'
          : 'DD-MM-YYYY',
    },
    loadingExport: false,
    fromDateNew: '',
    toDateNew: '',
  }),
  created() {},
  computed: {},
  methods: {
    exportToExcel() {
      this.loadingExport = true;

      this.$store
        .dispatch('workLogs/exportExcel', {
          from: formatDateToDDMM(this.fromDateNew),
          to: formatDateToDDMM(this.toDateNew),
          user_id: this.userFilter,
        })
        .then((response) => {
          if (response.path) {
            window.open(response.path, '_blank');
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.excelDownloadedSuccessfully'),
              color: 'green',
            });
            this.closeExportDialog();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loadingExport = false;
        });
    },
    closeExportDialog() {
      this.exportExcelDialog = false;
      this.$nextTick(() => {
        this.fromDateNew = null;
        this.toDateNew = null;
      });
      this.$refs.exportExcelForm.reset();
    },
    updateFromDateFilter(fromDateNew) {
      this.$refs.fromDateFilterNew.save(fromDateNew);
    },
    updateToDateFilter(toDateNew) {
      this.$refs.toDateFilterNew.save(toDateNew);
    },
  },

  watch: {
    exportExcelDialog(val) {
      if (val) {
        this.fromDateNew = this.fromDate;
        this.toDateNew = this.toDate;
      }
    },
  },
};
</script>

<style></style>
