<template>
  <v-col class="mb-5" cols="auto" lg="12" md="12" sm="12">
    <v-card>
      <v-data-table
        :footer-props="{
          pageText: `{0}-{1} ${$t('tables.of')} {2}`,
          itemsPerPageText: $t('tables.workLogsByPage'),
          itemsPerPageOptions: [5, 10, 20, 50, 100],
        }"
        :headers="workLogsHeaders"
        :no-data-text="$t('tables.noDataText')"
        :no-results-text="$t('tables.noDataText')"
        :items="workLogs"
        :search="searchString"
        class="elevation-1 cursor-pointer"
        @click:row="editItem"
        multi-sort>
        <template v-slot:top>
          <v-toolbar flat>
            <template>
              <v-toolbar-title>
                {{ $t('tables.workLogs') }}
              </v-toolbar-title>

              <v-divider class="mx-4" inset vertical></v-divider>
            </template>

            <v-text-field
              v-model="searchString"
              append-icon="mdi-magnify"
              hide-details
              :label="$t('tables.search')"
              single-line></v-text-field>

            <v-spacer />

            <div>
              <work-logs-export
                :from-date="fromDate"
                :to-date="toDate"
                :userFilter="userFilter"
                :classes="'d-none d-md-inline-flex mx-5 mb-2'" />

              <v-dialog v-model="workLogDialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                    {{ $t('buttons.add') }}
                  </v-btn>
                </template>
                <v-card>
                  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                    <v-form lazy-validation @submit.prevent="handleSubmit(save)">
                      <v-card-title class="info title white--text font-weight-bold">
                        {{ $t(formTitle) }}
                        <v-spacer />
                        <v-icon class="mdi mdi-close" style="color: white" @click="closeEdit"></v-icon>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <validation-provider rules="required" v-slot="{ errors }" name="user">
                                <v-autocomplete
                                  v-model="editedItem.user_id"
                                  :items="drivers"
                                  clearable
                                  item-text="full_name"
                                  item-value="id"
                                  :label="$t('formsAndTables.user')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <validation-provider rules="" v-slot="{ errors }" name="driving">
                                <v-autocomplete
                                  v-model="editedItem.driving_id"
                                  :items="drivings"
                                  clearable
                                  item-text="number"
                                  item-value="id"
                                  :label="$t('formsAndTables.driving')"
                                  :error-messages="errors"
                                  :no-data-text="$t('select.noDataAvailable')"
                                  @focus="$event.target.click()"></v-autocomplete>
                              </validation-provider>
                            </v-col>

                            <v-col cols="12" sm="6" md="6" lg="6">
                              <v-menu
                                ref="startedAtDate"
                                v-model="startedAtDate"
                                :close-on-content-click="false"
                                :return-value.sync="editedItem.started_at"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="" v-slot="{ errors }" name="started_at"
                                    ><v-text-field
                                      v-model="editedItem.started_at"
                                      clearable
                                      :label="$t('formsAndTables.startedAt')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  id="startedAt"
                                  v-model="editedItem.started_at"
                                  :minute-increment="1"
                                  :model-config="modelConfig"
                                  class="no-border"
                                  is24hr
                                  :first-day-of-week="firstDay"
                                  mode="dateTime">
                                </DatePicker>
                                <div class="date-picker-buttons">
                                  <v-btn class="primary cancel mr-2" elevation="2" text @click="startedAtDate = false">
                                    {{ $t('buttons.cancel') }}
                                  </v-btn>
                                  <v-btn
                                    class="primary mr-2"
                                    elevation="2"
                                    text
                                    @click="$refs.startedAtDate.save(editedItem.started_at)">
                                    {{ $t('buttons.confirm') }}
                                  </v-btn>
                                </div>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <v-menu
                                ref="finishedAtDate"
                                v-model="finishedAtDate"
                                :close-on-content-click="false"
                                :return-value.sync="editedItem.finished_at"
                                min-width="auto"
                                offset-y
                                transition="scale-transition">
                                <template v-slot:activator="{ on, attrs }">
                                  <validation-provider rules="" v-slot="{ errors }" name="finished_at"
                                    ><v-text-field
                                      v-model="editedItem.finished_at"
                                      clearable
                                      :label="$t('formsAndTables.finishedAt')"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      :error-messages="errors"></v-text-field>
                                  </validation-provider>
                                </template>
                                <DatePicker
                                  id="finishedAt"
                                  v-model="editedItem.finished_at"
                                  :minute-increment="1"
                                  :model-config="modelConfig"
                                  class="no-border"
                                  is24hr
                                  :first-day-of-week="firstDay"
                                  mode="dateTime">
                                </DatePicker>
                                <div class="date-picker-buttons">
                                  <v-btn class="primary cancel mr-2" elevation="2" text @click="finishedAtDate = false">
                                    {{ $t('buttons.cancel') }}
                                  </v-btn>
                                  <v-btn
                                    class="primary mr-2"
                                    elevation="2"
                                    text
                                    @click="$refs.finishedAtDate.save(editedItem.finished_at)">
                                    {{ $t('buttons.confirm') }}
                                  </v-btn>
                                </div>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                              <validation-provider
                                :rules="`${
                                  !editedItem.started_at || !editedItem.finished_at ? 'required|' : ''
                                }numeric|min_value:0`"
                                v-slot="{ errors }"
                                name="minutes"
                                ><v-text-field
                                  v-model="editedItem.minutes"
                                  clearable
                                  :label="$t('formsAndTables.minutes')"
                                  type="text"
                                  :error-messages="errors"></v-text-field> </validation-provider
                            ></v-col>
                            <v-col cols="12" md="12" sm="12">
                              <validation-provider rules="max:200" v-slot="{ errors }" name="description">
                                <v-textarea
                                  v-model="editedItem.note"
                                  clearable
                                  counter="255"
                                  :label="$t('formsAndTables.description')"
                                  :error-messages="errors"
                                  rows="2"
                                  auto-grow>
                                </v-textarea>
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                      </v-card-actions>
                    </v-form>
                  </validation-observer>
                </v-card>
              </v-dialog>
            </div>

            <v-dialog v-model="workLogDialogDelete" max-width="650">
              <v-card>
                <v-card-title class="info title white--text font-weight-bold">
                  {{ editedItem.name }}
                  <v-spacer />
                  <v-icon class="mdi mdi-close" style="color: white" @click="closeDelete"></v-icon>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <p>
                      {{ $t('workLogsPage.deleteMessage') }}
                    </p>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn :disabled="!enableSave" class="primary" text @click="deleteConfirm">
                    {{ $t('buttons.yes') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-tooltip left>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn text v-on="{ ...on, ...tooltipOn }">
                    <v-icon> mdi-dots-vertical </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('buttons.actions') }}
                </span>
              </v-tooltip>
            </template>
            <v-list>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="editItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.edit') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.edit') }}</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item @click="deleteItem(item)">
                    <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                    <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                      {{ $t('buttons.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ $t('tooltips.delete') }}</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import i18n from '@/i18n/i18n';
import { workLogsHeaders } from '@/mixins/data-table-headers';
import { defaultWorkLog } from '@/mixins/default-items';
import { DatePicker } from 'v-calendar/src/components';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { formatDateTime, formatDateToDDMM } from '@/utils/formatDate';
import store from '@/store';
import WorkLogsExport from '@/components/work-logs/WorkLogsExport.vue';

export default {
  name: 'workLogsList',
  components: { DatePicker, ButtonSubmit, WorkLogsExport },
  props: ['fromDate', 'toDate', 'userFilter'],
  data: () => ({
    searchString: '',
    workLogDialog: false,
    workLogDialogDelete: false,
    workLogs: [],
    formTitle: 'workLogsPage.newItem',
    editedItem: {},
    startedAtDate: null,
    finishedAtDate: null,
    drivings: [],
    driving: '',
    enableSave: true,
    modelConfig: {
      type: 'string',
      mask:
        store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
          ? 'MM-DD-YYYY HH:mm'
          : 'DD-MM-YYYY HH:mm',
    },
    loading: false,
  }),
  created() {
    this.editedItem = Object.assign(
      {},
      {
        ...defaultWorkLog,
      }
    );
    this.loadAllWorkLogs();
    this.loadAllDrivings();
  },
  computed: {
    workLogsHeaders() {
      return workLogsHeaders(i18n);
    },
    drivers() {
      return this.$store.getters['users/getDrivers'].map((item) => {
        return {
          ...item,
          full_name: item.profile?.full_name || 'N/A',
        };
      });
    },
  },
  methods: {
    async loadAllWorkLogs() {
      let data = {
        user_id: this.userFilter,
        from_date: this.fromDate ? formatDateToDDMM(this.fromDate) : null,
        to_date: this.toDate ? formatDateToDDMM(this.toDate) : null,
      };
      await this.$store.dispatch('workLogs/getAllWorkLogs', data).then((res) => {
        this.workLogs = res.data.map((item) => {
          return {
            ...item,
            user_id: item.user?.id,
            driving_id: item.driving?.id,
            created_at: formatDateTime(item.created_at),
            started_at: formatDateTime(item.started_at),
            finished_at: formatDateTime(item.finished_at),
            log_type_name: i18n.t('workLogsPage.' + item.log_type),
          };
        });
      });
    },
    async loadAllDrivings() {
      await this.$store.dispatch('drivings/getAllDrivingsData').then((res) => {
        this.drivings = res.data;
      });
    },

    editItem(item) {
      this.editedItem = this.workLogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.driving_id = parseInt(item?.driving_id);
      this.editedItem.minutes = item.minutes ? Number(item.minutes) : null;
      this.formTitle = item.name;
      this.workLogDialog = true;
    },

    deleteItem(item) {
      this.editedItem = this.workLogs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.workLogDialogDelete = true;
    },

    async save() {
      this.enableSave = false;
      this.loading = true;
      let path = 'saveWorkLog';
      let formData = this.createFormData();

      if (this.editedItem.id) {
        path = 'updateWorkLog';
      }

      await this.$store
        .dispatch('workLogs/' + path, formData)
        .then(() => {
          this.loadAllWorkLogs();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closeEdit();
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableSave = true;
          this.loading = false;
          this.formTitle = 'workLogsPage.newItem';
        });
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedItem.id ? this.editedItem.id : '');
      formData.append('log_type', 'regular');
      formData.append('minutes', this.editedItem.minutes ? this.editedItem.minutes : '');
      formData.append('started_at', this.editedItem.started_at ? formatDateToDDMM(this.editedItem.started_at) : '');
      formData.append('finished_at', this.editedItem.finished_at ? formatDateToDDMM(this.editedItem.finished_at) : '');
      formData.append('user_id', this.editedItem.user_id ? this.editedItem.user_id : '');
      formData.append('driving_id', this.editedItem.driving_id ? this.editedItem.driving_id : '');
      formData.append('note', this.editedItem.note ? this.editedItem.note : '');

      return formData;
    },

    async deleteConfirm() {
      await this.$store
        .dispatch('workLogs/deleteWorkLog', this.editedItem)
        .then((res) => {
          if (res.success) {
            this.loadAllWorkLogs();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closeDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closeDelete();
        });
    },

    closeEdit() {
      this.workLogDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          {},
          {
            ...defaultWorkLog,
          }
        );
      });
      this.$refs.form.reset();
      this.formTitle = 'workLogsPage.newItem';
    },

    closeDelete() {
      this.workLogDialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign(
          {},
          {
            ...defaultWorkLog,
          }
        );
      });
    },

    getCurrentDate() {
      const date = new Date();

      return this.getFormattedDate(date);
    },

    getFormattedDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
  },

  watch: {
    workLogDialog(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    fromDate: {
      handler() {
        this.loadAllWorkLogs();
      },
    },
    toDate: {
      handler() {
        this.loadAllWorkLogs();
      },
    },
    userFilter: {
      handler() {
        this.loadAllWorkLogs();
      },
    },
  },
};
</script>

<style>
#startedAt .vc-date,
#finishedAt .vc-date {
  display: none;
}

#startedAt,
#finishedAt {
  border: none;
  border-radius: 0;
  background-color: #fff;
}
</style>
